import React from 'react';

import Header from '@/components/layouts/header';
import { IconCarCv } from '@/components/layouts/header/icon-car-cv';
import { LogoIsuzu } from '@/components/layouts/header/logo-isuzu';
import {
  MENU_LCV_BUYING_TOOLS,
  MENU_LCV_PRIMARY_NAV,
  MENU_LCV_SECONDARY_NAV,
} from '@/contents/lcv/link';

interface IProps {
  children?: React.ReactNode;
  isTransparentNavigation?: boolean;
}

const View: React.FunctionComponent<IProps> = (props: IProps) => {
  return (
    <>
      <Header
        Logo={LogoIsuzu}
        logo={{
          src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt3034da103c5fd5df/66bdee8345395d2b5abb4983/logo-isuzu.svg',
          alt: 'Isuzu',
          width: 104,
          height: 18,
        }}
        IconCar={IconCarCv}
        menuBuyingTool={MENU_LCV_BUYING_TOOLS}
        menuModel={MENU_LCV_SECONDARY_NAV}
        menuMain={MENU_LCV_PRIMARY_NAV}
        isTransparentNavigation={props.isTransparentNavigation}
        showMenuPanel
      />
      {props.children}
    </>
  );
};
View.displayName = 'LCVLayout';
export default View;
